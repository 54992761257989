<template>
  <VCard>
    <div class="top-sticky white">
      <div class="d-flex justify-space-between align-center pa-3">
        <Breadcrumbs :items="breadcrumbs" />
        <VBtn icon @click="close">
          <VIcon v-text="'mdi-close'" />
        </VBtn>
      </div>
      <VDivider />
    </div>
    <VForm ref="form" lazy-validation>
      <VRow>
        <VCol class="pl-8 pr-4 min-h-500" cols="8">
          <p class="section-title my-6">Организация</p>
          <div class="d-flex align-center">
            <VTextField
              v-model="integrationToEdit.iikoApiLogin"
              clearable
              color="#4D4F67"
              label="Введите API Login"
              :rules="[(v) => !!v || 'Выберите значение']"
            />
            <Switcher
              class="mx-6"
              @input="$event === 'first' ? apiLoginType = 'new' : apiLoginType = 'existing'"
            />
            <VBtn
              class="elevation-0 border-2 br-8 font-weight-bold subtitle-2 mb-2"
              :disabled="!integrationToEdit.iikoApiLogin"
              height="40"
              outlined
              @click="applyApiLogin"
            >
              Применить
            </VBtn>
          </div>
          <div v-if="organizations.length">
            <span class="grey-subtitle">Укажите организацию</span>
            <VAutocomplete
              v-model="integrationToEdit.iikoOrganization"
              append-icon="mdi-chevron-down"
              attach
              background-color="#FAFAFB"
              color="#4D4F67"
              flat
              item-color="red"
              item-text="nameAndId"
              :items="organizations"
              :menu-props="{ maxHeight: '250px' }"
              no-data-text="Нет доступных организаций"
              placeholder="Выберите организацию"
              return-object
              :rules="rules.required"
              solo
              @change="handleOrganizationChange"
            />
            <VBtn
              v-if="!menuGroups.length && !loading"
              class="elevation-0 br-8 font-weight-bold subtitle-2 white--text mb-12"
              color="#E43539"
              :disabled="!integrationToEdit.iikoOrganization.id"
              height="40"
              @click="getOrganizationData"
            >
              Продолжить
            </VBtn>
          </div>
          <div v-if="menuGroups.length">
            <span class="section-title">Терминал</span>
            <VRow>
              <VCol cols="6">
                <div>
                  <span class="grey-subtitle">Укажите папку с меню</span>
                  <VAutocomplete
                    v-model="integrationToEdit.iikoMenuGroup"
                    append-icon="mdi-chevron-down"
                    attach
                    background-color="#FAFAFB"
                    color="#4D4F67"
                    flat
                    item-color="red"
                    item-text="nameAndId"
                    :items="menuGroups"
                    no-data-text="Нет доступных папок с меню"
                    placeholder="Выберите папку с меню"
                    return-object
                    :rules="rules.required"
                    solo
                  />
                </div>
                <div>
                  <span class="grey-subtitle">Укажите тип доставки</span>
                  <VAutocomplete
                    v-model="integrationToEdit.iikoOrderType"
                    append-icon="mdi-chevron-down"
                    attach
                    background-color="#FAFAFB"
                    color="#4D4F67"
                    flat
                    item-color="red"
                    item-text="nameAndId"
                    :items="deliveryTypes"
                    no-data-text="Нет доступных доставок"
                    placeholder="Выберите тип доставки"
                    return-object
                    :rules="rules.required"
                    solo
                  />
                </div>
              </VCol>
              <VCol cols="6">
                <div>
                  <span class="grey-subtitle">Укажите тип оплаты</span>
                  <VAutocomplete
                    v-model="integrationToEdit.iikoPaymentType"
                    append-icon="mdi-chevron-down"
                    attach
                    background-color="#FAFAFB"
                    color="#4D4F67"
                    flat
                    item-color="red"
                    item-text="nameAndId"
                    :items="paymentTypes"
                    no-data-text="Нет доступных оплат"
                    placeholder="Выберите тип оплаты"
                    return-object
                    :rules="rules.required"
                    solo
                  />
                </div>
                <div>
                  <span class="grey-subtitle">Укажите терминал</span>
                  <VAutocomplete
                    v-model="integrationToEdit.iikoTerminal"
                    append-icon="mdi-chevron-down"
                    attach
                    background-color="#FAFAFB"
                    color="#4D4F67"
                    flat
                    item-color="red"
                    item-text="nameAndId"
                    :items="terminalGroups"
                    no-data-text="Нет доступных терминалов"
                    placeholder="Выберите терминал"
                    return-object
                    :rules="rules.required"
                    solo
                  />
                </div>
              </VCol>
            </VRow>
            <span class="section-title">Ресторан</span>
            <VRow class="min-h-400">
              <VCol cols="6">
                <span class="grey-subtitle">Укажите интеграционного партнера</span>
                <VAutocomplete
                  v-model="integrationToEdit.partner"
                  append-icon="mdi-chevron-down"
                  attach
                  background-color="#FAFAFB"
                  color="#4D4F67"
                  flat
                  item-color="red"
                  item-text="title"
                  item-value="id"
                  :items="partners"
                  no-data-text="По заданному значению партнеры не найдены"
                  placeholder="Выберите интеграционного партнера"
                  return-object
                  :rules="rules.required"
                  :search-input.sync="partnersSearch"
                  solo
                  @change="handlePartnerChange"
                />
              </VCol>
              <VCol cols="6">
                <span class="grey-subtitle">Укажите ресторан</span>
                <VAutocomplete
                  v-model="integrationToEdit.partnerRestaurant"
                  append-icon="mdi-chevron-down"
                  attach
                  background-color="#FAFAFB"
                  color="#4D4F67"
                  :disabled="!integrationToEdit.partner.id"
                  flat
                  :hide-details="!integrationToEdit.partner.id"
                  item-color="red"
                  item-text="hermesName"
                  :items="partnerRestaurants"
                  no-data-text="Нет доступных ресторанов по выбранному партнеру"
                  placeholder="Выберите ресторан"
                  return-object
                  :rules="rules.required"
                  solo
                />
              </VCol>
            </VRow>
          </div>
        </VCol>
        <VDivider vertical/>
        <VCol class="pr-8 pl-4 pt-4">
          <span class="grey-subtitle">Дата создания интеграции</span>
          <div class="my-4">
            <VIcon
              class="mr-2"
              v-text="'mdi-calendar-blank-outline'"
            />
            <span>
              {{
                integrationToEdit.createdDt
                ? new Date(integrationToEdit.createdDt).toLocaleDateString('ru')
                : new Date().toLocaleDateString('ru')
              }}
            </span>
          </div>
          <VDivider/>
        </VCol>
      </VRow>
    </VForm>
    <div class="bottom-sticky z-1000">
      <VDivider />
      <div
        class="d-flex align-center grey lighten-5 px-4 py-3"
        :class="(loading || integrationToEdit.id) ? 'justify-space-between' : 'justify-end'"
      >
        <RotatingIcon
          v-if="loading"
          text="Загружается..."
        />
        <div
          v-if="!loading && integrationToEdit.id"
          class="text--secondary"
        >
          {{
             integrationToEdit.createdDt === integrationToEdit.updatedDt ? 'Создано' : 'Изменено'
          }}:
          {{ formatDateWithTime(integrationToEdit.createdDt) }}
        </div>
        <div class="subtitle-2">
          <VBtn
            id="save-btn"
            class="subtitle-2 br-8 white--text font-weight-bold blue-grey-disabled--text"
            color="#E43539"
            :disabled="loading"
            elevation="0"
            height="40"
            @click="save"
          >
            {{ integrationToEdit.id ? 'Сохранить' : 'Создать' }} интеграцию
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>

import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';
import {
  getIIKOToken,
  getIIKOOrganizations,
  getIIKOOrganizationData,
  getIIKOPaymentTypes,
  getIIKODeliveryTypes,
  getIIKOTerminalGroups,
  getPartnersList,
  getPartnerRestaurants,
  createSRIntegration,
  updateSRIntegration,
  fetchSRIntegrationOrganizations,
} from '@/api/api';
import { formatDateWithTime } from '@/helpers/date';
import RotatingIcon from '@/components/reusable/RotatingIcon.vue';
import Switcher from '@/components/reusable/Switcher.vue';

export default {
  components: { Switcher, RotatingIcon, Breadcrumbs },
  props: {
    integration: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      organizations: [],
      menuGroups: [],
      paymentTypes: [],
      deliveryTypes: [],
      terminalGroups: [],
      partners: [],
      partnerRestaurants: [],
      partnersSearch: '',
      integrationToEdit: {
        iikoApiLogin: '',
        iikoMenuGroup: {},
        iikoOrderType: {},
        iikoOrganization: {},
        iikoPaymentType: {},
        iikoTerminal: {},
        partner: {},
        partnerRestaurant: {},
      },
      apiLoginType: 'new',
      loading: false,
      rules: {
        required: [(value) => !!Object.keys(value).length || 'Выберите значние'],
      },
      formatDateWithTime,
    };
  },
  async created() {
    if (this.integration.id) {
      this.integrationToEdit = JSON.parse(JSON.stringify(this.integration));
      await this.getOrganizationsByToken();
      this.getOrganizationData();
      this.partners = [this.integrationToEdit.partner];
      this.getPartnerRestaurants();
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { text: 'Smart Restaurant', disabled: false, href: '/smart-restaurant' },
        {
          text: this.integration && this.integration.id ? `Интеграция ID-${this.integration.id}` : 'Создать новую интеграцию',
          disabled: true,
          href: '',
        },
      ];
    },
  },
  methods: {
    applyApiLogin() {
      this.resetIntegrationToEdit();
      this.getOrganizationsByToken();
    },
    async getOrganizationsByToken() {
      await getIIKOToken(this.integrationToEdit.iikoApiLogin).then(() => {
        if (this.apiLoginType === 'new') {
          getIIKOOrganizations().then((organizations) => {
            this.organizations = organizations;
          });
        } else {
          fetchSRIntegrationOrganizations(this.integrationToEdit.iikoApiLogin)
            .then((organizations) => {
              this.organizations = organizations;
            });
        }
      });
    },
    close() {
      this.$emit('close');
    },
    getOrganizationData() {
      this.loading = true;
      if (this.integrationToEdit.iikoOrganization.srOrganizationId) {
        // eslint-disable-next-line max-len
        this.integrationToEdit.srOrganizationId = this.integrationToEdit.iikoOrganization.srOrganizationId;
      }
      getIIKOOrganizationData(this.integrationToEdit.iikoOrganization.id).then((response) => {
        this.menuGroups = response || [];
        this.menuGroups.unshift({ nameAndId: 'Все меню', name: '', id: null });
      }).finally(() => {
        this.loading = false;
      });
      getIIKOPaymentTypes(this.integrationToEdit.iikoOrganization.id).then((response) => {
        this.paymentTypes = response || [];
      });
      getIIKODeliveryTypes(this.integrationToEdit.iikoOrganization.id).then((response) => {
        this.deliveryTypes = response || [];
      });
      getIIKOTerminalGroups(this.integrationToEdit.iikoOrganization.id).then((response) => {
        this.terminalGroups = response || [];
      });
    },
    getPartners(search) {
      getPartnersList({ search }).then((response) => {
        this.partners = response.result;
      });
    },
    getPartnerRestaurants(page = 1) {
      getPartnerRestaurants(this.integrationToEdit.partner.id, page)
        .then(async (response) => {
          if (response.current_page === 1) {
            this.partnerRestaurants = response.result;
          } else {
            this.partnerRestaurants = this.partnerRestaurants.concat(response.result);
          }

          if (response.current_page < response.total_page) {
            await this.getPartnerRestaurants(response.current_page + 1);
          }
        });
    },
    handlePartnerChange() {
      this.integrationToEdit.partnerRestaurant = {};
      this.getPartnerRestaurants();
    },
    handleOrganizationChange() {
      this.integrationToEdit.iikoMenuGroup = {};
      this.integrationToEdit.iikoPaymentType = {};
      this.integrationToEdit.iikoTerminal = {};
      this.integrationToEdit.iikoOrderType = {};
      this.menuGroups = [];
    },
    resetIntegrationToEdit() {
      this.organizations = [];
      this.integrationToEdit.iikoOrganization = {};
      this.handleOrganizationChange();
    },
    createIntegration() {
      this.loading = true;
      createSRIntegration(this.integrationToEdit).then(() => {
        this.$emit('update');
        this.close();
      }).finally(() => {
        this.loading = false;
      });
    },
    updateIntegration() {
      this.loading = true;
      updateSRIntegration(this.integrationToEdit).then(() => {
        this.$emit('update');
        this.close();
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.integrationToEdit.id) {
        this.updateIntegration();
      } else {
        this.createIntegration();
      }
    },
  },
  watch: {
    partnersSearch(search) {
      if (search.length) {
        this.getPartners(search);
      } else {
        this.partners = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

::v-deep {
  .mdi-chevron-down {
    font-size: 18px;
  }
  .v-autocomplete__content.v-menu__content {
    border-radius: 8px !important;
    background-color: white;
  }
  .v-list-item {
    margin: 8px;
    min-height: 30px;
    &__title {
      font-size: 14px;
      text-wrap: wrap;
    }
    &__content {
      padding: 4px 0;
    }
    &--link:hover {
      border-radius: 4px;
    }
  }
  .v-list-item--link:hover, .v-list-item--link:before {
    background-color: #FFEEEE !important;
  }
  .v-list-item--active::before {
    opacity: 0 !important;
  }
  .v-select__slot input::placeholder {
    color: #4D4F67;
  }
  .v-input--is-disabled input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
</style>
