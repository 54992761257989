<template>
  <div class="switcher d-flex align-center px-1 relative">
    <div
      class="switch active"
      :class="{ 'translate-right': activeSwitch === 'second' }"
    >
      {{ activeSwitch === 'first' ? firstTitle : secondTitle }}
    </div>
    <button
      class="switch z-1"
      type="button"
      @click="activeSwitch = 'first'"
    >
      {{ firstTitle }}
    </button>
    <button
      class="switch z-1"
      type="button"
      @click="activeSwitch = 'second'"
    >
      {{ secondTitle }}
    </button>
  </div>
</template>

<script>

export default {
  props: {
    firstTitle: {
      type: String,
      default: 'Новый',
    },
    secondTitle: {
      type: String,
      default: 'Существующий',
    },
  },
  data() {
    return {
      activeSwitch: 'first',
    };
  },
  created() {
    this.activeSwitch = this.value || 'first';
  },
  watch: {
    activeSwitch() {
      this.$emit('input', this.activeSwitch);
    },
  },
};

</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

.switcher {
  background-color: #F4F6FF;
  border: 0.5px solid #E6E0EC;
  border-radius: 24px;
  height: 46px;
}

.switch {
  border-radius: 24px;
  height: 38px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 0 16.5px;
  transition: all 0.2s ease-in-out;
}

.active {
  background-color: white;
  position: absolute;
  left: 4px;
  color: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.translate-right {
  transform: translateX(54%);
}
</style>
