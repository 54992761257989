<template>
  <div>
    <div class="pt-5 px-8">
      <PageHeader
        create-btn-title="Создать интеграцию"
        page-title="Smart Restaurant"
        :searchable="false"
        @click:create="edit"
      />
      <VDivider class="my-4" />
    </div>
    <UniversalTable
      ref="table"
      :headers="srIntegrationsHeaders()"
      :items="integrations"
      :page="currentPage"
      :top-margin="200"
      @delete="deleteIntegration"
      @edit="edit"
      @update-page="currentPage = $event"
    />
    <VDialog
      v-model="integrationDialog"
      content-class="br-12"
      max-width="1200"
    >
      <SRIntegration
        :key="key"
        :integration="integrationToEdit"
        @close="integrationDialog = false"
        @update="getIntegrations"
      />
    </VDialog>
  </div>
</template>

<script>
import PageHeader from '@/components/reusable/PageHeader.vue';
import { fetchSRIntegrations, deleteSRIntegration } from '@/api/api';
import SRIntegration from '@/components/SRIntegration.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import { srIntegrationsHeaders } from '@/conditions/tablesHeaders';
import { itemsPerPage } from '@/helpers/table';

export default {
  components: {
    UniversalTable,
    SRIntegration,
    PageHeader,
  },
  data() {
    return {
      integrations: [],
      integrationDialog: false,
      integrationToEdit: {},
      currentPage: 1,
      key: 0,
      srIntegrationsHeaders,
    };
  },
  mounted() {
    this.getIntegrations();
  },
  methods: {
    getIntegrations() {
      this.$refs.table.loading = true;
      fetchSRIntegrations({
        page: this.currentPage,
        page_size: itemsPerPage,
      }).then((response) => {
        this.integrations = response || [];
        this.$refs.table.totalCount = this.integrations[0]?.totalCount || 0;
        this.$refs.table.totalPage = this.integrations[0]?.totalCount / itemsPerPage || 0;
      }).finally(() => {
        this.$refs.table.loading = false;
      });
    },
    edit(integration = {}) {
      this.integrationToEdit = integration;
      this.key += 1;
      this.integrationDialog = true;
    },
    deleteIntegration(integration) {
      deleteSRIntegration(integration.id).then(() => {
        this.getIntegrations();
      });
    },
  },
  watch: {
    currentPage() {
      this.getIntegrations();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

</style>
